/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

// start the Stimulus application
const $ = require('jquery');
require('bootstrap');

import 'lazysizes';
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";

document.addEventListener('DOMContentLoaded', function () {
  // Initialisez LazySizes
  lazySizes.init();

  // Mettez à jour la propriété 'background-image' pour les images en background-image 
  document.addEventListener('lazybeforeunveil', function (e) {
    var bg = e.target.getAttribute('data-bg');
    if (bg) {
      e.target.style.backgroundImage = 'url(' + bg + ')';
    }
    // Ajouter l'effet de fondu à toutes les images en mode Lazy
    e.target.classList.add('lazyfadein');
  });
});

window.toggleIcon = function() {
  var icon = document.getElementById("menuIcon");
  icon.classList.toggle("fa-ellipsis-vertical");
  icon.classList.toggle("fa-times");
};



document.addEventListener("DOMContentLoaded", function () {
  var elem = document.querySelector(".anim-text");
  const text = document.querySelector('.anim-text');
  const letters = text.textContent.split('');
  text.textContent = '';
  if (elem) {
    for (let i = 0; i < letters.length; i++) {
      const span = document.createElement('span');
      span.textContent = letters[i];
      span.style.animationDelay = i * 50 + 'ms';
      text.append(span);

      setTimeout(function () {
        span.classList.add('is-visible');
      }, i * 50);
    }
  }
});

// document.addEventListener("DOMContentLoaded", function() {
//   const elements = document.querySelectorAll('.filter');

//   const options = {
//     rootMargin: '0px',
//     threshold: 0.2
//   };

//   const observer = new IntersectionObserver(function(entries, observer) {
//     entries.forEach(entry => {
//       if (entry.intersectionRatio > 0) {
//         entry.target.classList.add('fade');
//         observer.unobserve(entry.target);
//       }
//     });
//   }, options);

//   elements.forEach(element => {
//     observer.observe(element);
//   });
// });

document.addEventListener("DOMContentLoaded", function() {
  var navbar = document.querySelector('.navbar');

  window.addEventListener("scroll", function() {
      if (window.scrollY > 50) {
          navbar.classList.add('navbar-scrolled');
      } else {
          navbar.classList.remove('navbar-scrolled');
      }
  });
});

window.addEventListener('load', function () {
  document.body.classList.add('font-loaded');
});

document.addEventListener("DOMContentLoaded", function() {
  const captionElements = document.querySelectorAll('.video-legend');
  captionElements.forEach(function(captionElement) {
    const captionText = captionElement.innerHTML;
    const hashtagsRegex = /#(\w+)/g;
    const urlsRegex = /(https?:\/\/[^\s]+)/g;

    const processedText = captionText.replace(hashtagsRegex, '<span class="hashtag">#$1</span>');
    const processedTextWithUrls = processedText.replace(urlsRegex, '<a href="$&" target="_blank">$&</a>');

    captionElement.innerHTML = processedTextWithUrls;
  });
});


// Fonction pour gérer l'intersection des éléments avec le viewport
function handleIntersection(entries, observer) {
  entries.forEach(function (entry) {
    if (entry.isIntersecting) {
      entry.target.classList.add('visible');
      observer.unobserve(entry.target);
    }
  });
}

// Observer les éléments avec la classe 'fade-in'
function observeFadeInElements() {
  var elements = document.querySelectorAll('.fade-in');
  var options = {
    root: null, // La racine est l'viewport
    rootMargin: '0px', // Aucune marge supplémentaire
    threshold: 0.2, // Le pourcentage visible nécessaire pour déclencher l'observation
  };

  var observer = new IntersectionObserver(handleIntersection, options);
  elements.forEach(function (element) {
    observer.observe(element);
  });
}

// Appeler la fonction pour commencer à observer les éléments au chargement de la page
observeFadeInElements();




///F/////////////////// GESTION AFFICHAGE PRIX///////////////////////////////////
var minInput = document.getElementById('min');
var maxInput = document.getElementById('max');

// écouter l'événement "input" pour les champs de prix min et max
minInput.addEventListener('input', formatPrix);
maxInput.addEventListener('input', formatPrix);

function formatPrix() {
  // récupérer la valeur actuelle du champ de prix
  var prix = this.value;

  // remplacer tous les caractères qui ne sont pas des chiffres par une chaîne vide
  prix = prix.replace(/\D/g, '');

  // formater la valeur en ajoutant des points comme séparateurs de milliers
  prix = Intl.NumberFormat().format(prix);

  // affecter la valeur formatée au champ de prix
  this.value = prix;
}

// récupération du formulaire et du bouton de validation
const form = document.querySelector('form');
const submitBtn = document.querySelector('#submit-btn');

// ajout d'un événement sur le clic du bouton de validation
submitBtn.addEventListener('click', function () {
  // récupération des champs min et max
  const minInput = document.getElementById('min');
  const maxInput = document.getElementById('max');

  // suppression des espaces
  minInput.value = minInput.value.replace(/\s/g, '');
  maxInput.value = maxInput.value.replace(/\s/g, '');

  // soumission du formulaire
  form.submit();
});

window.addEventListener('load', function () {
  // récupération des champs min et max
  const minInput = document.getElementById('min');
  const maxInput = document.getElementById('max');

  // ajout d'espaces entre les chiffres
  minInput.value = minInput.value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  maxInput.value = maxInput.value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
});
///F///////////////////FIN GESTION AFFICHAGE PRIX///////////////////////////////////

